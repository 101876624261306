'use client';
import React from 'react';

export default function NotFound() {
	return (
		<html lang="en">
			<body>
				<div className="mx-auto flex h-screen items-center justify-center pt-header-height">
					<div className="flex max-w-[800px] flex-col justify-center space-y-10">
						<h2 className="m-0 text-center text-4xl font-bold">
							404 - Lehekülge ei leitud
						</h2>

						<p className="max-h-24 overflow-hidden text-clip text-center leading-8 text-muted-navy">
							Antud veebilehte ei eksisteeri. Leht võib olla kustutatud või lehe
							asukoht võib olla muudetud.
						</p>

						<a href="/">Mine avalehele</a>
					</div>
				</div>
			</body>
		</html>
	);
}
